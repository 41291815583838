<template>
  <div style="background: #f5f5f5;height: 100%;">
    <van-nav-bar title="我推荐的客户" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="padding-top: 46px;">
      <div class="heds">
        <van-row style="font-size: 13px;">
          <van-col span="2" class="ls">#</van-col>
          <van-col span="6" class="ls">车牌</van-col>
          <van-col span="4" class="rs">车主姓名</van-col>
          <van-col span="6" class="ls">电话号码</van-col>
          <van-col span="6" class="rs">注册时间</van-col>
        </van-row>
        <van-row v-for="(item,idx) in list" :key="idx">
          <van-col span="2" class="ls">{{idx+1}}</van-col>
          <van-col span="6" class="ls" style="">
            <img class="xiaobiao" v-if="item.partnerLevel == 1" src="../../assets/img/hehuobiao.png" alt="">
            <!-- <span class="xiao" v-if="item.partnerLevel == 1">消</span> -->
            <span style="vertical-align: middle;">{{item.carNo}}</span>
            
          </van-col>
          <van-col span="4" class="rs">{{item.customerName}}</van-col>
          <van-col span="6" class="ls">{{item.phone}}</van-col>
          <van-col span="6" class="rs" @click.native="totuijianxq(item)">
            <span style="vertical-align: middle">{{item.createdDate.substring(0,10)}}</span>
            <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
export default {
  data() {
    return {
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      nameList: {}
    };
  },
  methods: {
    onLoad() {
      userwei.tuokelonger({ customerId: this.userInfo.id }).then(res => {
        this.list = res.data;
      });
    },

    back() {
      this.$router.go(-1);
    },
    totuijianxq(item) {
      if (item.partnerLevel == 0) {
        this.$router.push({
          name: "indextwo",
          query: {
            customerId: item.customerId,
            carNo: item.carNo,
            customerName: item.customerName,
            phone: item.phone,
            gid: item.gid
          }
        });
      } else {
        this.$router.push({
          name: "tuoke_recommendTwo",
          query: {
            customerId: item.customerId,
            offId:item.offId,
            customerName:item.customerName,
            gid:item.gid
          }
        });
      }
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.creatQrCode();
  }
};
</script>

<style lang="less" scoped>
.heds {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 10px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  min-height: 220px;
}
// .xiao {
//   background: red;
//   display: inline-block;
//   font-size: 12px;
//   line-height: 20px;
//   padding: 2px 1px;
//   color: #fff;
//   border-radius: 8px;
// }
.van-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.van-row {
  text-align: center;

  line-height: 45px;
  border-bottom: 1px solid #f5f5f5;
}
.xiaobiao{
  width: 15px;
  vertical-align: middle;
  margin-right: 4px;
}
</style>
